import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">
        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >

          <ul className="aducation-box theme-bg">
            <li>
              <span className="dark-bg">01/06/2020 à aujourd’hui</span>
              <h6>SOPRA STERIA [ CDI - Limonest ]</h6>
              <p>Développeur Symfony v2 et 3 - Interne au service entité immobilière social et privé développement sur l’application echoPark.</p>
            </li>

            <li>
              <span className="dark-bg">14/01/2020 au 24/02/2020</span>
              <h6>EVS PRO [ CDI - Saint-Didier-au-Mont-d'Or ]</h6>
              <p>Développeur full stack - Développement 2 sites wordpress.</p>
            </li>

            <li>
              <span className="dark-bg">7/03/2016 au 13/01/2020</span>
              <h6>CKSEVEN [ CDI - Saint-Priest ]</h6>
              <p>Développeur full stack - Conception d’applications web & mobile IOS/ANDROID (React Native) pour les négociants automobile, facturation, gestion de stock, site, internet, mailbox, diffuseurs d’annonces, atelier, stock, données fabricants (options ﬁnitions modèles marques). Prise des photos & documents et véhicule depuis un mobile via l'application. Accompagnement d’un plateau de 5 développeurs sur le site de Tunis en mode collaboratif. Première expérience avec Symfony 3 sur un développement interne du back office de la société.</p>
            </li>
          </ul>

          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >

          <ul className="aducation-box dark-bg">
            <li>
              <span className="theme-bg">2014 à 2016</span>
              <h6>ELTITEC [ CDI - Lyon 03 ]</h6>
              <p>Développeur full stack - Responsable du développement P.I.M. (gestion des données produits), intégration des données clients, gestion des serveurs, R&D, gestion des échanges en direct avec les clients / prestataires (COFRISET, SIEMENS HEALTHCARE, ROCHE DIAGNOSTICS, NORAUTO/MPS, L2G…).</p>
            </li>

            <li>
              <span className="theme-bg">2012 à 2014</span>
              <h6>SORECSON [ CDI - Villeurbanne]</h6>
              <p>Développeur PHP - Développement de divers projets pour grands comptes et PME, R&D, conception backoﬃce,intégrations des newsletters et sites. Distri-Partner www.distri-partner.fr : Gestion de projet, développement du backofﬁce ERP (module de suivi des missions, CRM, CV thèque, utilisateurs multi niveaux, gestion planning,API Google calendar etc..) MRS & Marc Veyrat www.mesbocaux.fr : Intégration du site, paiement en ligne mesbocaux.fr, Backoﬃce (camions, stocks, commandes, planning etc...) DUNK! www.wearedunk.com : Intégration et développement du site en HTML5. Gras Savoye www.grassavoye-montagne.com : Intégration du site et développement du backoffice de gestion (commandes, forfaits, planning etc...) Siemens laboratoires : Développement et intégration d’un site responsive pour tablettes.</p>
            </li>


          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}


        <div
            className="col-lg-4 my-3"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="100"
        >

          <ul className="aducation-box dark-bg">
            <li>
              <span className="theme-bg">2007 à 2009</span>
              <h6>DIGYTALIS SARL [Gérant]</h6>
              <p>Société spécialisée dans la fourniture de contenu en ligne basée sur un vaste réseau de sites. Gestion des serveurs, développement de sites internet, R&D.</p>
            </li>

            <li>
              <span className="theme-bg">2007 à 2009</span>
              <h6>DIGYTALIS SARL [Gérant]</h6>
              <p>Société spécialisée dans la fourniture de contenu en ligne basée sur un vaste réseau de sites. Gestion des serveurs, développement de sites internet, R&D.</p>
            </li>

            <li>
              <span className="theme-bg">2005 à 2006</span>
              <h6>ARMÉE DE TERRE</h6>
              <p>Service militaire - 1 an au 93ème Régiment d'Artillerie de Montagne.</p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default Skills;
